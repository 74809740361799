import React from "react";

const Advantages = () => {
  return (
    <div className="block advantages bg-light w-100 skew">
      <div className="container">
        <h1 className="text-center">Por qu&eacute; Gur&uacute; Delivery?</h1>
        <div className="row mt-5 justify-content-center">
          <div className="col-12 col-md-9 col-lg-3 mb-5 mb-lg-0">
            <div className="card" data-aos="fade-up" data-aos-once="true">
              <img class="card-img-top" src="./assets/images/buy.svg" height={128} />
              <div className="card-body">
                <h5 class="card-title mb-4">
                  Pide lo que te gusta de donde quieras
                </h5>
                <p className="card-text">
                  Con Gur&uacute; no te limitas a pedir de un solo lugar,puedes
                  ordenar lo que quieras de cualquier establecimiento.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-9 col-lg-3 mb-5 mb-lg-0">
            <div className="card" data-aos="fade-up" data-aos-delay="50" data-aos-once="true">
            <img class="card-img-top" src="./assets/images/notifications.svg" height={128} />
              <div className="card-body">
                <h5 class="card-title mb-4">Rastrea el estado de tu pedido</h5>
                <p className="card-text">
                  Verifica en tiempo real si tu pedido esta en
                  preparaci&oacute;n, ha sido enviado o si ha llegado a tu
                  ubicaci&oacute;n.
                </p>
                <p className="card-text">
                  Ser&aacute;s notificado cada vez que el estado de tu pedido
                  cambia.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-9 col-lg-3">
            <div className="card" data-aos="fade-up" data-aos-delay="50" data-aos-once="true">
            <img class="card-img-top" src="./assets/images/locations.svg" height={128} />
              <div className="card-body">
                <h5 class="card-title mb-4">
                  No m&aacute;s problemas con direcciones
                </h5>
                <p className="card-text">
                  Olv&iacute;date de las llamadas tediosas sobre las direciones.
                  Gur&uacute; se encarga de gestionar las ubicaciones donde
                  deseas que llevemos tus pedidos.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advantages;
