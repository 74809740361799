import { setTitle } from "../helpers";

const Privacy = () => {
  setTitle("Política de Privacidad");
  return (
    <div className="container page privacy">
      <h1>Política de Privacidad</h1>
      <p className="text-muted my-3">Última actualización: Enero 25, 2021</p>
      <p>
        Gurú Delivery, en adelante GURÚ, conforme a la legislación vigente en
        materia de Protección de Datos de Carácter Personal, informa a los
        Usuarios de su plataforma online, acerca de la Política de Privacidad y
        Protección de Datos que aplicará en el tratamiento de los datos
        personales que el Usuario facilite voluntariamente al acceder al Sitio{" "}
        <a href="https://guru.delivery">www.guru.delivery</a> o a la correspondiente
        aplicación móvil.
      </p>
      <ol>
        <li>
          <h3>CONSENTIMIENTO DE ALMACENAMIENTO Y USO DE INFORMACIÓN</h3>
          <ol>
            <li>
              <h3>Recopilación de información</h3>
              <p>
                Al registrarse como USUARIO en nuestra APP, o acceder nuestros
                SERVICIOS o comunicarse con GURU como USUARIO VISITANTE en
                nuestro SITIO WEB, APP o cualquier otro medio, usted acepta
                libre y expresamente que su INFORMACIÓN PERSONAL, sea
                consultada, procesada, tratada y almacenada por GURU.
              </p>
              <p>
                Salvo que específicamente se establezca lo contrario, todos los
                datos que GURÚ le solicita en los procesos de registro y/o de
                compra de productos y/o solicitud de SERVICIOS en el SITIO WEB o
                en la APP son necesarios para poder hacer el registro y/o la
                prestación de los SERVICIOS que GURÚ ofrece.
              </p>
              <p>
                El usuario autoriza a guru la obtención de sus datos personales
                públicos de su cuenta de facebook por lo que será su
                responsabilidad que la información sea real, completa y
                actualizada al momento del ingreso de la información en la base
                de datos vinculada con el SITIO WEB y la APP. La omisión o
                inexactitud en alguno de ellos podría implicar la imposibilidad
                de que podamos prestarle los SERVICIOS solicitados y/o atender
                sus solicitudes y facultará a GURÚ para tomar acciones tales
                como pero no limitadas a: eliminar la cuenta del USUARIO, no
                brindar los SERVICIOS requeridos, o que los datos suministrados
                por el USUARIO sean eliminados de la base de datos.
              </p>
            </li>
            <li>
              <h3>
                Información que recopilamos a través del uso de nuestros
                Servicios
              </h3>
              <p>
                Cuando el USUARIO utiliza nuestros SERVICIOS, recopilamos
                INFORMACIÓN PERSONAL en las siguientes categorías generales:
              </p>
              <ul>
                <li>
                  <p>
                    <b>Información de ubicación:</b> Cuándo, qué tan menudo y
                    bajo qué circunstancias utiliza nuestros SERVICIOS de
                    entrega bajo demanda, recopilamos datos de ubicación exacta
                    o aproximada. Si usted permite a la aplicación de GURÚ
                    acceder a los SERVICIOS de ubicación a través del sistema de
                    permisos que utiliza su sistema operativo para móviles,
                    también podremos recopilar la ubicación exacta de su
                    dispositivo cuando la aplicación se esté ejecutando. También
                    podemos obtener su ubicación aproximada a partir de su
                    dirección IP.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Información de la operación:</b> Recopilamos datos
                    relacionados con el uso de nuestros SERVICIOS, incluyendo el
                    tipo de servicio solicitado, la fecha y el momento de la
                    prestación del SERVICIO, cantidad cargada, distancia
                    recorrida, tiempo de entrega y otros detalles relacionados
                    con la operación.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Información del ordenador:</b> Cuando el USUARIO
                    interactúa con los SERVICIOS, recopilamos los registros del
                    servidor, que pueden incluir información como direcciones IP
                    del dispositivo, fechas y horas de acceso, funciones de la
                    aplicación utilizadas, páginas visitadas y uso del SITIO WEB
                    o APP, bloqueos de la aplicación y otra actividad del
                    sistema, tipo y versión de navegador, sistema operativo y el
                    sitio de terceros o servicio que estaba utilizando antes de
                    interactuar con nuestros Servicio.
                  </p>
                </li>
              </ul>
            </li>
          </ol>
        </li>
        <li>
          <h3>COOKIES Y OTROS SISTEMAS</h3>
          <p>
            Cuando el USUARIO visita nuestro SITIO WEB y/o nuestra APP algunos
            de los datos de navegación se envían desde su computador o
            dispositivo móvil a nuestros servidores. Esta información permite
            optimizar nuestros SERVICIOS, personalizar y mejorar su experiencia
            en nuestro SITIO WEB o la APP. Esta información puede incluir
            aspectos tales como pero no limitados a:
          </p>
          <ul>
            <li>La fecha y hora de su visita</li>
            <li>Las funciones que utilizó dentro de la APP.</li>
            <li>Información sobre el navegador que utilizó.</li>
            <li>Tiempo que estuvo en el SITIO WEB y/o en la APP.</li>
            <li>
              Cantidad de veces que ingreso al SITIO WEB y/o la APP y las veces
              que visitó alguna página del SITIO WEB o utilizó alguna de las
              funciones disponibles en la App.
            </li>
          </ul>
          <p>
            GURÚ utiliza varias tecnologías para recopilar información cuando el
            USUARIO visita nuestro SITIO WEB y/o nuestra APP y esto puede
            incluir el envío de una o más cookies u otros identificadores para
            su dispositivo. Los sistemas y tecnologías que utilizamos son los
            siguientes:
          </p>
          <ol>
            <li>
              <h3>Tecnología de Geolocalización</h3>
              <p>
                La tecnología de geolocalización permite la determinación de la
                ubicación del USUARIO por medio de las coordinadas geográficas
                (latitud y longitud) en un mapa común. La información será
                obtenida por medio de la identificación del IP de un aparato
                conectado a internet, por medio de un proveedor de red, por
                medio de un aparato con red GSM o por medio de un GPS, por lo
                que serán aplicables, a tales servicios de geolocalización, los
                términos y condiciones del tercero proveedor. Por la manera en
                que funciona el SITIO WEB y la APP, si el USUARIO desactiva o
                bloquea la utilización de este sistema, se pueden ocasionar
                errores en el funcionamiento y/o la utilización del SITIO WEB y
                la APP.
              </p>
              <p>
                Para evitar que su aparato permita el uso de las tecnologías
                antes dichas, el USUARIO debe rechazar la solicitud de
                autorización que se desplegará en la pantalla de su aparato,
                previo a la navegación por determinadas secciones de nuestro
                SITIO WEB y la APP.
              </p>
              <p>
                Si el USUARIO tiene alguna duda sobre las tecnologías antes
                dichas, cómo funcionan, cómo desactivarlas o cualquier otro tipo
                de inquietud en relación con las que se utilizan en nuestro
                SITIO WEB y la APP, deberá comunicarse con nosotros por medio de
                las líneas telefónicas disponibles en la sección de
                “Contáctanos” del sitio web de GURÚ.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>ASPECTOS DE SEGURIDAD DE LOS DATOS Y TRANSACCIONES</h3>
          <p>
            Estamos comprometidos a proteger su INFORMACIÓN PERSONAL y hemos
            adoptado las medidas técnicas y organizativas adecuadas para
            proteger dicha información contra cualquier pérdida, alteración,
            acceso o uso indebido.
          </p>
          <p>
            GURÚ le informa que sus Datos Personales son almacenados en una base
            de datos propiedad de esta, y se le garantizan las medidas adecuadas
            de índole técnica y estructural para garantizar la integridad y
            seguridad de la INFORMACIÓN PERSONAL aportada de acuerdo con el
            estado actual de la técnica.
          </p>
          <p>
            GURÚ mantiene los niveles de seguridad de protección de sus datos
            conforme a la legislación aplicable, relativo a las medidas de
            seguridad de las bases de datos que contengan datos de carácter
            personal y ha establecido todos los medios técnicos razonables, a su
            alcance, para evitar la pérdida, mal uso, alteración, acceso no
            autorizado y robo de los datos que el USUARIO comunique a GURÚ, sin
            perjuicio de informarle de que las medidas de seguridad en Internet
            no son infalibles.
          </p>
          <p>
            A través de nuestra plataforma no se guarda información de tarjeta
            de crédito ni débito de ninguno de los tarjetahabientes. Esta
            información, en ningún momento será retenida, divulgada o
            registrada, si no que utiliza procesadores terceros de pago. La
            única información que pasa hacia la plataforma del tercero son los
            datos del propietario, correo electrónico y monto total del importe
            a pagar. Dicha información está sujeta a los términos de esta
            POLÍTICA.
          </p>
        </li>
        <li>
          <h3>LIBERACIÓN DE RESPONSABILIDAD</h3>
          <p>
            El USUARIO reconoce y acepta que el uso del SITIO WEB y la APP, se
            realiza en todo momento bajo su entero riesgo y responsabilidad.
            GURÚ no será responsable por el uso indebido que pueda realizarse de
            estos medios de comunicación y/o tecnologías.
          </p>
          <p>
            GURÚ no será responsable por daños y perjuicios de cualquier tipo
            causados en los equipos electrónicos (cómputo, teléfono, tableta, u
            otros) del USUARIO por virus, gusanos o cualquier otro elemento
            dañino. El USUARIO reconoce que el uso de la Internet supone la
            asunción de un riesgo de que sus equipos informáticos puedan ser
            afectados por los elementos antes enunciados. A tal efecto,
            corresponde al USUARIO, en todo caso, la disponibilidad de
            herramientas adecuadas para la detección y eliminación de programas
            electrónicos dañinos que puedan suponer un riesgo para su equipo de
            cómputo.
          </p>
          <p>
            GURÚ no tendrá responsabilidad por la falta de ejecución de sus
            obligaciones cuando medie fuerza mayor, caso fortuito o la entrada
            en vigencia de legislación gubernamental, regulaciones o
            restricciones de cualquier otra índole que le impidan la ejecución
            de sus obligaciones.
          </p>
        </li>
        <li>
          <h3>CANCELACIÓN DE SERVICIOS Y ELIMINACIÓN DE DATOS</h3>
          <p>
            El usuario posee la facultad de cancelar su cuenta de GURÚ y la
            eliminación de los datos personales guardados en nuestra base de
            datos en el momento que desee contactandonos las líneas telefónicas
            disponibles en la sección de “Contáctanos” del sitio web de GURÚ y
            brindando la información que será solicitada al momento de la
            llamada.
          </p>
        </li>
        <li>
          <h3>CAMBIOS EN NUESTRA POLÍTICA DE PRIVACIDAD</h3>
          <p>
            GURÚ se reserva la posibilidad de realizar cambios o ajustes a la
            presente Política de Privacidad. Si ejecutamos modificaciones o
            ajustes a esta Política de Privacidad se lo notificaremos a la hora
            de utilizar los SERVICIOS o por alguno de los medios de contacto
            indicados por el USUARIO para recibir comunicaciones de parte de
            GURÚ.
          </p>
        </li>
      </ol>
    </div>
  );
};

export default Privacy;
