import React, { useEffect, useRef } from "react";

const Header = () => {
  const header = useRef(0);
  useEffect(() => {
    const scrollHandler = (e) => {
      if (window.scrollY >= 40) {
        if (!header.current.classList.contains("filled"))
          header.current.classList.add("filled");
      } else {
        header.current.classList.remove("filled");
      }
    };
    window.addEventListener("scroll", scrollHandler);

    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);
  return (
    <nav id="navbar" ref={header} class="navbar fixed-top navbar-light fixed">
      <div class="container">
        <a className="navbar-brand" href="https://guru.delivery/">
          <img src="./assets/images/logo.svg" alt="Gurú Delivery" width="32" />
        </a>

        <div class="navbar-nav ml-auto">
          <a
            className="btn btn-dark rounded-pill font-weight-bold"
            aria-current="page"
            href="https://app.guru.delivery/"
          >
            <i class="fas fa-sign-in-alt"></i> Ingresar
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Header;
