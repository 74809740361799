import { setTitle } from "../helpers";

const Terms = () => {
  setTitle("Términos y Condiciones");
  return (
    <div className="container page privacy">
      <h1>Términos y Condiciones</h1>
      <p className="text-muted my-3">Última actualización: Enero 26, 2021</p>
      <p>
        Los presentes términos y condiciones de servicio regulan el acceso o uso
        que el usuario haga de la aplicación, páginas web, contenido, productos
        y servicios puesto a disposición por <b>GURÚ DELIVERY</b>. El servicio
        se encuentra dirigido exclusivamente a residentes en la República de
        Honduras. Los Usuarios se encontrarán sujetos a los Términos y
        Condiciones Generales respectivos, junto con todas las demás políticas y
        principios que rigen <b>GURÚ</b> y que son incorporados al presente por
        referencia.
      </p>
      <p>
        CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES GENERALES,
        LOS CUALES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ
        ABSTENERSE DE UTILIZAR EL SITIO y/o LA APLICACIÓN MÓVIL.
      </p>
      <p>
        El Usuario debe leer, entender y aceptar todas las condiciones
        establecidas en los Términos y Condiciones Generales y en las Políticas
        de Privacidad.
      </p>
      <p>
        <b>GURÚ</b> facilita la intermediación entre repartidores independientes
        (en adelante los <b>"Repartidores"</b>) y personas físicas/jurídicas que
        requieren de Servicio aplicable (en adelante
        <b>"Usuarios"</b>) mediante el uso de una plataforma tecnológica y móvil
        de <b>GURÚ</b> (en adelante la <b>"Aplicación"</b>) entendiéndose dicha
        operación a los efectos de los presentes Términos y Condiciones como
        “Servicio” ejecutado a través de terceros proveedores independientes de
        dichos servicios, incluidos transportistas independientes y proveedores
        logísticos independientes, conforme a un acuerdo con GURÚ o algunos de
        los afiliados de <b>GURÚ</b>. <b>GURÚ</b> actúa en todo momento como
        tercero intermediario entre Proveedores, Repartidores y Usuarios.
        Asimismo, usted reconoce que <b>GURÚ</b> no presta servicios de
        mensajería, transporte ni logística. Bajo ninguna circunstancia los
        Repartidores serán considerados empleados por <b>GURÚ</b> ni por ninguno
        de sus Proveedores. Los Repartidores prestan el Servicio por cuenta y
        riesgo propio y liberan a los Usuarios de cualquier responsabilidad que
        pudiera surgir durante la prestación del Servicio de Reparto.
      </p>
      <p>
        <b>GURÚ</b> se reserva el derecho de cambiar los presentes términos y
        condiciones en cualquier momento y sin responsabilidad alguna,
        teniéndose como aceptados por el Usuario. Por lo anterior, se recomienda
        al Usuario leer de manera diaria y periódica estos términos y
        condiciones detenidamente previo a acceder o usar la Aplicación.
      </p>
      <p>
        Como consecuencia de lo anterior, mediante el acceso y uso por parte del
        Usuario de la Aplicación acepta expresamente vincularse jurídicamente a
        los presentes términos y condiciones.
      </p>
      <div>
        <h3>CLÁUSULA PRIMERA: El Registro</h3>
        <p>
          1.1. El acceso a la Aplicación es gratuito, salvo en lo relativo al
          costo de la conexión a través de la red de telecomunicaciones
          suministrada por el proveedor de acceso contratado por el Usuario, que
          estará a su exclusivo cargo. El Usuario únicamente podrá acceder a la
          Aplicación a través de los medios autorizados.
        </p>
        <p>
          1.2. Para el acceso a los contenidos de la Aplicación será necesario
          el registro del Usuario. Por ello, para acceder a los servicios
          prestados por los Repartidores, el Usuario deberá contar con un
          Smartphone con sistema operativo IOS o Android, una cuenta de facebook
          y completar todos los campos del proceso de inscripción
          correspondiente a los Usuarios con datos válidos (en adelante, el{" "}
          <b>“Usuario Registrado”</b> o en plural
          <b>“Usuarios Registrados”</b>). Quien aspire a convertirse en Usuario
          Registrado deberá verificar que la información que pone a disposición
          de GURÚ sea exacta, precisa y verdadera (en adelante, los{" "}
          <b>“Datos Personales”</b>). Asimismo el Usuario Registrado asumirá el
          compromiso de actualizar los Datos Personales cada vez que los mismos
          sufran modificaciones. GURÚ podrá utilizar diversos medios para
          identificar a los Usuarios Registrados, pero GURÚ no se responsabiliza
          por la certeza de los Datos Personales que sus Usuarios Registrados
          pongan a su disposición. Los Usuarios Registrados garantizan y
          responden, en cualquier caso, de la veracidad, exactitud, vigencia y
          autenticidad de los Datos Personales puestos a disposición de GURÚ.
        </p>
        <p>
          1.3. A los efectos de adquirir la condición de Usuario Registrado de
          GURÚ, el Usuario deberá aceptar la Política de Privacidad, y los
          presentes Términos y Condiciones.
        </p>
        <p>
          1.4. Una vez efectuado el Registro, GURÚ otorgará al Usuario
          Registrado una cuenta personal (en adelante la “Cuenta”). El Usuario
          Registrado accederá a su Cuenta mediante su cuenta personal de
          facebook. La Cuenta es personal, única e intransferible, y está
          prohibido que un mismo Usuario Registrado registre o posea más de una
          Cuenta. En caso que GURÚ detecte distintas Cuentas que contengan datos
          coincidentes o relacionados, podrá cancelar, suspender o
          inhabilitarlas. El Usuario será el único responsable por el uso de su
          Cuenta, liberando de cualquier responsabilidad a GURÚ. Una vez
          registrada la cuenta del Usuario, se constituye de manera automática
          el consentimiento expreso en los presentes términos y condiciones por
          parte del Usuario.
        </p>
        <p>
          1.5. Los Datos Personales obtenidos del Usuario Registrado deberán ser
          exactos, actuales y veraces en todo momento. GURÚ se reserva el
          derecho de solicitar algún comprobante y/o dato adicional a efectos de
          corroborar los Datos Personales, y de suspender temporal y/o
          definitivamente a aquel Usuario Registrado cuyos datos no hayan podido
          ser confirmados. GURÚ NO se responsabiliza por la certeza de los datos
          consignados en el Registro. El Usuario Registrado garantiza y
          responde, en cualquier caso, de la veracidad, exactitud, vigencia y
          autenticidad de sus Datos Personales. Los Datos Personales que el
          Usuario Registrado proporcione se integrarán en una base de datos
          personales del que es responsable GURÚ. Para más información consultar
          la Política de Privacidad.
        </p>
      </div>
      <div>
        <h3>CLÁUSULA SEGUNDA: Condiciones Generales del Uso del Servicio</h3>
        <p>
          2.1. El Servicio se encuentra dirigido exclusivamente a los Usuarios
          Registrados.
        </p>
        <p>
          2.2. El Usuario Registrado podrá encontrar en la plataforma productos
          ofrecidos por distintos comercios (los <b>“Proveedores”</b>). El fin
          de ello es que el Usuario Registrado pueda dar aviso de manera
          inmediata a un Repartidor que desea el envío de un producto de un
          Proveedor.
        </p>
        <p>
          2.3. Un mismo Usuario Registrado podrá solicitar hasta un número
          razonable de Servicios por día, reservándole a GURÚ la posibilidad de
          bloquear al Usuario Registrado, por cuestiones de seguridad ante
          posibilidades de fraude, estafa, uso de datos ajenos, etc.
        </p>
        <p>
          2.4. GURÚ podrá, a su exclusivo criterio, rechazar y/o frenar
          cualquier pedido de los Usuarios Registrados.
        </p>
        <p>
          2.5. El Repartidor cumplirá el Servicio entregando la mercadería a la
          persona que surge de los Detalles de Entrega, siendo el Usuario
          Registrado el único responsable por la veracidad de los datos allí
          introducidos. Asimismo, cada Usuario Registrado es responsable de la
          actividad que ocurre en su cuenta.
        </p>
        <p>
          2.6. La Aplicación no está disponible para el uso de personas menores
          de 15 años. Así como tampoco el Usuario podrá autorizar a terceros a
          utilizar su cuenta. El Usuario tampoco podrá ceder o transferir de
          otro modo su cuenta a cualquier otra persona o entidad.
        </p>
        <p>
          2.7. En algunos casos, se le podrá requerir que facilite un documento
          de identidad u otro elemento de verificación de identidad para el
          acceso o uso de la Aplicación, y el Usuario acepta que se le podrá
          denegar el acceso o uso de la Aplicación si se niega facilitar el
          documento de identidad o el elemento de verificación de identidad.
        </p>
      </div>
      <div>
        <h3>CLÁUSULA TERCERA: Condiciones Particulares del Uso del Servicio</h3>
        <p>
          3.1. Una vez que se hayan incorporado en la Aplicación todos los datos
          necesarios para efectuar la solicitud de un Servicio, y declarado
          haber aceptado expresamente estos términos y condiciones, GURÚ
          mostrará todos los productos de los Proveedores disponibles cons sus
          respectivos precios (incluyendo impuestos). En el paso siguiente, se
          informará el resumen del Servicio en donde constará el precio total a
          abonar por el Usuario Registrado.
        </p>
        <p>
          3.2. Una vez seleccionados los productos que se deseen solicitar por
          intermedio de la Aplicación, será requisito indispensable que el
          Usuario Registrado incorpore los datos exigidos en los campos allí
          establecidos y, una vez cumplido ello, se podrá emitir la solicitud
          correspondiente.
        </p>
        <p>
          3.3. Efectuada la solicitud antedicha, inmediatamente quedará
          confirmada la misma.
        </p>
      </div>
      <div>
        <h3>CLÁUSULA CUARTA: Condiciones de Pago y Facturación</h3>
        <p>
          4.1. Las tarifas aplicables al Servicio las fijará GURÚ conforme a la
          demanda de servicios que se encuentre activa en ese momento en la
          Aplicación. El Usuario acepta que sea GURÚ quien fije estas tarifas.
          Estas tarifas serán cobradas personalmente por el Repartidor y
          posteriormente retenidas por GURÚ. Las tarifas cobradas por el
          Servicio no serán reembolsables.
        </p>
        <p>
          4.2. Durenate y al finalizar el Servicio, GURÚ enviará notificaiones
          al Usuario Registrado con el estado de su solicitud.
        </p>
        <p>
          4.3. GURÚ se reserva el derecho de tomar las medidas judiciales y
          extrajudiciales que estime pertinentes para obtener el pago del monto
          debido.
        </p>
        <p>
          4.4. El Usuario reconoce y acepta que los costos aplicables en
          determinadas zonas geográficas podrá incrementar sustancialmente
          durante el periodo de alta demanda o por la distancia de la misma.
        </p>
        <p>
          4.5. GURÚ realizará sus mejores esfuerzos para informar al Usuario de
          los costos que podrán aplicarse, siempre y cuando el Usuario sea
          responsable de los costos incurridos en su cuenta, independientemente
          de que el Usuario conozca dichos costos o los importes de estos.
        </p>
      </div>
      <div>
        <h3>CLÁUSULA QUINTA: Uso de la Aplicación</h3>
        <p>
          5.1. GURÚ tendrá las facultades para denegar o restringir el uso de la
          Aplicación a cualquier Usuario bajo su exclusivo criterio, sin generar
          perjuicio alguno al Usuario. GURÚ no será responsable si el Usuario no
          cuenta con un teléfono celular inteligente compatible con el uso de la
          Aplicación. El Usuario se compromete a hacer un uso adecuado y lícito
          de la Aplicación de conformidad con la legislación aplicable, los
          presentes Términos y Condiciones, la moral y buenas costumbres
          generalmente aceptadas y el orden público. Al utilizar la Aplicación o
          el Servicio, el Usuario acuerda que:
        </p>
        <ul>
          <li>
            Solo utilizará el Servicio para su uso personal y no tendrá
            facultades para revender su Cuenta a un tercero.
          </li>
          <li>No autorizará a terceros a usar su Cuenta.</li>
          <li>
            No cederá ni transferirá de otro modo su Cuenta a ninguna otra
            persona o entidad legal.
          </li>
          <li>
            No utilizará una cuenta que esté sujeta a cualquier derecho de una
            persona que no sea ella sin la autorización adecuada.
          </li>
          <li>
            No solicitará el Servicio con fines ilícitos, ilegales, contrarios a
            lo establecido en los presentes Términos y Condiciones, a la buena
            fe y al orden público, lesivos de los derechos e intereses de
            terceros incluyendo, sin limitación, el transporte de material
            ilegal o con fines fraudulentos.
          </li>
          <li>
            No tratará de dañar el Servicio o la Aplicación de ningún modo, ni
            accederá a recursos restringidos en la Aplicación.
          </li>
          <li>
            Guardará de forma segura y confidencial cualquier identificación
            facilitada para permitirle acceder al Servicio y la Aplicación.
          </li>
          <li>
            No utilizará el Servicio o la Aplicación con un dispositivo
            incompatible o no autorizado.
          </li>
          <li>
            No intentará acceder, utilizar y/o manipular los datos de GURÚ,
            Proveedores, Repartidores y otros Usuarios.
          </li>
          <li>
            No introducirá ni difundirá introducir o difundir virus informáticos
            o cualesquiera otros sistemas físicos o lógicos que sean
            susceptibles de provocar daños en la Aplicación.
          </li>
        </ul>
      </div>
      <div>
        <h3>CLAUSULA SEXTA: Cancelaciones y Penalidades</h3>
        <p>
          6.1. Una vez emitida la Solicitud, el Usuario Registrado no podrá
          cancelarla a través de la Aplicación o algún otro medio.
        </p>
        <p>
          6.2. Al querer efectuarse la Cancelación, el Usuario estará obligado a
          abonar el 100% del monto correspondiente al pago del Servicio.
        </p>
        <p>
          6.3. El Usuario en ningún caso podrá alegar falta de conocimiento de
          las limitaciones, restricciones y penalidades, dado que las mismas son
          informadas en forma previa a realizar la solicitud del Servicio a
          través de la Aplicación.
        </p>
      </div>
      <div>
        <h3>CLÁUSULA SEPTIMA: Responsabilidad</h3>
        <p>
          7.1. GURÚ sólo pone a disposición del Repartidor un espacio virtual
          que les permite ponerse en comunicación mediante la Aplicación para
          brindar Servicios a los Usuarios. GURÚ interviene en el
          perfeccionamiento de las operaciones realizadas entre el Repartidor y
          el Usuario procurando la calidad, estado, integridad o legitimidad de
          la mercadería transportada por el Repartidor.
        </p>
      </div>
      <div>
        <h3>CLÁUSULA OCTAVA: Uso y Garantía de la Aplicación</h3>
        <p>
          8.1. GURÚ no garantiza la disponibilidad y continuidad del
          funcionamiento de la Aplicación. Por lo anterior, GURÚ en cualquier
          momento podrá suspender o cancelar el funcionamiento de la Aplicación
          sin responsabilidad alguna de su parte. En consecuencia, GURÚ no será
          en ningún caso responsable por cualquier daño y/o perjuicio que puedan
          derivarse de (i) la falta de disponibilidad o accesibilidad a la
          Aplicación; (ii) la interrupción en el funcionamiento de la Aplicación
          o fallos informáticos, averías telefónicas, desconexiones, retrasos o
          bloqueos causados por deficiencias o sobrecargas en las líneas
          telefónicas, centros de datos, en el sistema de Internet o en otros
          sistemas electrónicos, producidos en el curso de su funcionamiento; y
          (iii) otros daños que puedan ser causados por terceros mediante
          intromisiones no autorizadas ajenas al control de GURÚ.
        </p>
        <p>
          8.2. GURÚ no garantiza la ausencia de virus ni de otros elementos en
          la Aplicación introducidos por terceros ajenos a GURÚ que puedan
          producir alteraciones en los sistemas físicos o lógicos del Usuario o
          en los documentos electrónicos y ficheros almacenados en sus sistemas.
          En consecuencia, GURÚ no será en ningún caso responsable de
          cualesquiera daños y perjuicios de toda naturaleza que pudieran
          derivarse de la presencia de virus u otros elementos que puedan
          producir alteraciones en los sistemas físicos o lógicos, documentos
          electrónicos o ficheros del Usuario.
        </p>
        <p>
          8.3. GURÚ adopta diversas medidas de protección para proteger la
          Aplicación y los contenidos contra ataques informáticos de terceros.
          No obstante, GURÚ no garantiza que terceros no autorizados no puedan
          conocer las condiciones, características y circunstancias en las
          cuales el Usuario accede a la Aplicación. En consecuencia, GURÚ no
          será en ningún caso responsable de los daños y perjuicios que pudieran
          derivarse de dicho acceso no autorizado.
        </p>
        <p>
          8.4. Con la suscripción de las presentes Términos y Condiciones, Ud.
          declara que mantendrá indemne frente a cualquier reclamación a GURÚ,
          su sociedad matriz, directores, socios, empleados, abogados y agentes
          derivadas del (i) incumplimiento por parte del Usuario de cualquier
          disposición contenida las presentes en los Términos y Condiciones o de
          cualquier ley o regulación aplicable a las mismas, (ii) incumplimiento
          o violación de los derechos de terceros incluyendo, a título meramente
          enunciativo, otros Usuarios, peatones; y (iii) incumplimiento del uso
          permitido de la Aplicación.
        </p>
      </div>
      <div>
        <h3>CLÁUSULA NOVENA: Derechos de Propiedad Intelectual e Industrial</h3>
        <p>
          9.1. El Usuario reconoce y acepta que todos los derechos de propiedad
          intelectual e industrial sobre los contenidos y/o cualesquiera otros
          elementos insertados en la Aplicación (incluyendo, sin limitación,
          marcas, logotipos, nombres comerciales, textos, imágenes, gráficos,
          diseños, sonidos, bases de datos, software, diagramas de flujo,
          presentación, audio y vídeo), pertenecen a GURÚ.
        </p>
        <p>
          9.2. GURÚ autoriza al Usuario única y exclusivamente a utilizar,
          visualizar, imprimir, descargar y almacenar los contenidos y/o los
          elementos insertados en la Aplicación exclusivamente para su uso
          personal, privado y no lucrativo, absteniéndose de realizar sobre los
          mismos cualquier acto de descompilación, ingeniería inversa,
          modificación, divulgación o suministro. Cualquier otro uso o
          explotación de cualesquiera contenidos y/u otros elementos insertados
          en la Aplicación distinto de los aquí expresamente previstos estará
          sujeto a la autorización previa de GURÚ.
        </p>
      </div>
      <div>
        <h3>CLÁUSULA DÉCIMA: Protección de Datos</h3>
        <p>
          10.1. Los datos personales (en lo sucesivo, los “Datos”) que Ud.
          proporciona en el Registro se integrarán en una base de datos
          personales del que es responsable GURÚ, cuya dirección figura en el
          encabezamiento del presente documento.
        </p>
      </div>
      <div>
        <h3>CLÁUSULA DÉCIMA PRIMERA: Notificaciones</h3>
        <p>
          11.1. GURÚ podrá realizar las notificaciones oportunas al Usuario
          Registrado a través de una notificación general en la Aplicación, a
          través de mensajes de texto, la dirección de correo electrónico
          facilitada por el Usuario en su Cuenta. El Usuario podrá notificar a
          GURÚ de cualquier circunstancia mediante el envío de un mensaje de
          texto a través del chat de ayuda disponible al interior de la
          Aplicación. Así mismo, en el supuesto que el Usuario notifique a GURÚ
          su deseo de no recibir más mensajes o notificaciones de GURÚ, reconoce
          y acepta automáticamente que al no recibir las notificaciones esto
          podrá afectar el uso que haga de la Aplicación.
        </p>
      </div>
      <div>
        <h3>CLÁUSULA DÉCIMA SEGUNDA: Cesión</h3>
        <p>
          12.1. El Usuario Registrado no podrá ceder sus derechos y obligaciones
          de los presentes Términos y Condiciones sin el previo consentimiento
          escrito de GURÚ. GURÚ podrá ceder, sin necesidad de consentimiento
          previo del Usuario, los presentes Términos y Condiciones a cualquier
          entidad comprendida dentro de su grupo de sociedades, en todo el
          mundo, así como a cualquier persona o entidad que le suceda en el
          ejercicio de su negocio por cualesquiera títulos.
        </p>
      </div>
      <div>
        <h3>CLÁUSULA DÉCIMA TERCERA: Ley Aplicable y Jurisdicción</h3>
        <p>
          13.1. Las presentes Términos y Condiciones, así como la relación entre
          GURÚ y el Usuario, se regirán e interpretarán con arreglo a la
          legislación vigente en la República de Honduras, debiendo cualquier
          divergencia relacionadas a los presentes resolverse ante los
          tribunales ordinarios de la Ciudad de Tocoa, Honduras.
        </p>
      </div>
    </div>
  );
};

export default Terms;
