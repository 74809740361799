import { Link } from "react-router-dom";
import { APPSTORE_URL, PLAYSTORE_URL } from "../constants";

const Footer = () => {
  return (
    <footer className="block footer bg-dark text-white">
      <div className="container">
        <div className="d-flex row justify-space-between">
          <div className="col-12 col-md-6 col-lg-4">
            <h6>Descarga nuestra app</h6>
            <div className="app-buttons mt-2">
              <a className="badge google" href={PLAYSTORE_URL}>
                <img src="./assets/images/playstore_badge_es.svg" />
              </a>
              <a className="badge apple" href={APPSTORE_URL}>
                <img src="./assets/images/appstore_badge_es.svg" />
              </a>
            </div>
            <div className="footer-links inner mt-4">
              <ul className="nav">
                <li className="nav-item">
                  <Link to="/politica-privacidad">
                    Pol&iacute;tica de Privacidad
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/terminos-condiciones">
                    T&eacute;rminos y Condiciones
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-6 col-md-3 footer-links social mt-5 mt-md-0">
            <h6>Cont&aacute;ctanos</h6>
            <ul className="nav flex-column">
              <li className="nav-item">
                <span>(+504) 9853-6460</span>
              </li>
              <li className="nav-item">
                <span>(+504) 9837-4050</span>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-3 footer-links social mt-5 mt-md-0">
            <h6>S&iacute;guenos</h6>
            <ul className="nav">
              <a
                className="d-flex align-items-center"
                href="https://www.facebook.com/gurudeliveryhn"
              >
                <i className="fab fa-facebook-square"></i>
              </a>
            </ul>
          </div>
          <div className="col-12 company mt-5">
            &copy; {new Date().getFullYear()} <b>Gur&uacute; Delivery</b>.
            <p className="slogan text-muted">
              Ordena, nosotros te lo llevamos.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
