import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./layouts/Home";
import Privacy from "./layouts/Privacy";
import Terms from "./layouts/Terms";
import Header from "./components/Header";
import Footer from "./components/Footer";

const App = () => {
  return (
    <div className="App">
      <Router>
        <Header />

        <Switch>
          <Route path="/" exact={true} component={Home} />
          <Route path="/politica-privacidad" exact={true} component={Privacy} />
          <Route path="/terminos-condiciones" exact={true} component={Terms} />
        </Switch>

        <Footer />
      </Router>
    </div>
  );
};

export default App;
