import React from "react";
import { APPSTORE_URL, PLAYSTORE_URL } from "../../constants";

const Hero = () => {
  return (
    <div className="hero d-flex position-relative vh-100 align-items-center ">
      <div className="container">
        <div className="row justify-content-center justify-content-lg-between">
          <div className="col-12  col-lg-6 position-relative hero-text order-2 order-lg-1">
            <h1 className="guru">
              Gur&uacute; <span>Delivery</span>
            </h1>
            <h2>Ordena, nosotros te lo llevamos.</h2>
            <p className="description text-black-50">
              No importa el lugar donde est&eacute;s, tu casa, oficina o con tus
              amigos, solo ordena y con gusto te lo llevamos.
            </p>

            <p className="description mt-5">Descarga nuestra app</p>
            <div className="app-buttons mt-2">
              <a className="badge google" href={PLAYSTORE_URL}>
                <img src="./assets/images/playstore_badge_es.svg" />
              </a>
              <a className="badge apple" href={APPSTORE_URL}>
                <img src="./assets/images/appstore_badge_es.svg" />
              </a>
            </div>
          </div>
          <div className="col-6 col-lg-4 hero-phone order-1 order-lg-2 mb-4 mb-lg-0">
            <img className="img-fluid" src="./assets/images/guruapp.png" />
          </div>
        </div>
      </div>

      <div
        className="hero-bg w-75"
        data-aos="fade-left"
        style={{ backgroundImage: "url(./assets/images/hero-bg.svg)" }}
      ></div>
    </div>
  );
};

export default Hero;
