import Hero from "./Blocks/Hero";
import Advantages from "./Blocks/Advantages";
import { setTitle } from "../helpers";

const Home = () => {
  setTitle();
  return (
    <div className="body">
      <Hero />
      <Advantages />
    </div>
  );
};

export default Home;
